import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { DividerBlock } from '../dividerBlock';
import { useSingleExhibitionQuery } from '../../hooks/useSingleExhibitionQuery';
import { useRemoveHtmlFromString } from '../../hooks/useRemoveHtmlFromString';
import { useHandleDate } from '../../hooks/useHandleDate';
import ImageFadeIn from "react-image-fade-in";

export const HomeExhibitions = ({ accentColor }) => {
  const data = useSingleExhibitionQuery();
  const currentContemporaryExhibitions = data.currentContemporaryExhibitions.edges;
  const upcomingContemporaryExhibitions = data.upcomingContemporaryExhibitions.edges;
  const currentGlasshouseExhibitions = data.currentGlasshouseExhibitions.edges;
  const upcomingGlasshouseExhibitions = data.upcomingGlasshouseExhibitions.edges;
  const currentOffsiteExhibitions = data.currentOffsiteExhibitions.edges;
  const upcomingOffsiteExhibitions = data.currentOffsiteExhibitions.edges;

  const contemporaryExhibitionsBlock = currentContemporaryExhibitions.length >= 1
    ? currentContemporaryExhibitions : upcomingContemporaryExhibitions;
  const glasshouseExhibitionBlock = currentGlasshouseExhibitions.length === 1
    ? currentGlasshouseExhibitions : upcomingGlasshouseExhibitions;
  const offsiteExhibtionBlock = currentOffsiteExhibitions === 1
    ? currentOffsiteExhibitions : upcomingOffsiteExhibitions;

  const sliderRef = useRef(null);
  const renderCustomSlickArrows = () => (
    <div className="container row slick-controls">
      <div className="col-sm-5 col-xs-3 offset-xs-3 offset-sm-7 col">
        <div className="slick-arrow-container sans--regular">
          <button type="button" className="arrow slick-arrow-prev" aria-label="Previous" onClick={() => sliderRef.current.slickPrev()} />
          <button type="button" className="arrow slick-arrow-next" aria-label="Next" onClick={() => sliderRef.current.slickNext()} />
        </div>
      </div>
    </div>
  );

  return (
    <section className="home-current-exhibitions" aria-labelledby="home-exhibitions">
      <h2 id="home-exhibitions" className="vh">Featured exhibitions</h2>
      {contemporaryExhibitionsBlock.length > 0 && (
        <div className="exhibition-row">
          <div className="row container">
            <div className="col col-sm-7 col-xs-4 offset-xs-1 offset-sm-2 first">
              <DividerBlock accentColor={accentColor} />
            </div>
          </div>
          <Slider
            infinite
            fade
            speed={700}
            autoplay
            autoplaySpeed={5000}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            ref={sliderRef}
          >
            {contemporaryExhibitionsBlock.map(({ node: exhibition }) => (
              <Link to={`exhibition/${exhibition.slug}`} key={exhibition.id} className="exhibition-link">
                <div className="home-exhibition-tile container row">
                  <div className="image col col-sm-9 col-xs-5">
										<ImageFadeIn
										src={exhibition.featuredImage.url}
										alt={exhibition.featuredImage.alt}
										className="exhibition-image"
										/>
                  </div>
                  <div className="text col col-sm-5 col-xs-6">
                    <h2 className="exhibition-title sans--large" dangerouslySetInnerHTML={{ __html: exhibition.formattedTitle }} />
                    <div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: exhibition.locations[0].location.title }} />
                    <div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: useHandleDate(exhibition.endDateText, exhibition.startDate, exhibition.endDate, exhibition.exhibitionStatus) }} />
                  </div>
                </div>
              </Link>
            ))}
          </Slider>
          {contemporaryExhibitionsBlock.length > 1 && renderCustomSlickArrows(sliderRef)}
        </div>
      )}
      {glasshouseExhibitionBlock.length > 0 && (
        <>
          <div className="exhibition-row">
            <div className="row container">
              <div className="col col-sm-5 col-xs-3 offset-xs-3 offset-sm-7">
                <DividerBlock accentColor={accentColor} />
              </div>
            </div>
            <div className="home-exhibition-tile container row reverse">
              <div className="image col col-sm-7 col-xs-5 offset-xs-1">
                <Link to={`exhibition/${glasshouseExhibitionBlock[0].node.slug}`} className="glasshouse-link">
									<ImageFadeIn
										src={glasshouseExhibitionBlock[0].node.featuredImage.url}
										alt={glasshouseExhibitionBlock[0].node.featuredImage.alt}
										className="exhibition-image"
										/>
                </Link>
              </div>
              <div className="text col col-sm-5 offset-sm-2 col-xs-5 offset-xs-1">
                <Link to={`exhibition/${glasshouseExhibitionBlock[0].node.slug}`} className="glasshouse-link">
                  <h2 className="exhibition-title sans--large" dangerouslySetInnerHTML={{ __html: glasshouseExhibitionBlock[0].node.formattedTitle }} />
                  <div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: glasshouseExhibitionBlock[0].node.locations[0].location.title }} />
                  <div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: useHandleDate(glasshouseExhibitionBlock[0].node.endDateText, glasshouseExhibitionBlock[0].node.startDate, glasshouseExhibitionBlock[0].node.endDate, glasshouseExhibitionBlock[0].node.exhibitionStatus) }} />
                </Link>
              </div>
            </div>
          </div>
          <div className="exhibition-row">
            <div className="row container">
              <div className="col col-sm-7 col-xs-4 offset-xs-1 offset-sm-2">
                <DividerBlock accentColor={accentColor} />
              </div>
            </div>
          </div>
        </>
      )}
      {offsiteExhibtionBlock.length > 0 && (
        <div className="exhibition-row">
          <div className="row">
						<div className="home-exhibition-tile container row">
							<div className="image col col-sm-7 offset-sm-2 col-xs-5">
								<Link to={`exhibition/${offsiteExhibtionBlock[0].node.slug}`} className="offsite-link">
									<ImageFadeIn
										src={offsiteExhibtionBlock[0].node.featuredImage.url}
										alt={offsiteExhibtionBlock[0].node.featuredImage.alt}
										className="exhibition-image"
										/>
								</Link>
							</div>
							<div className="text col col-sm-5 col-xs-6">
								<Link to={`exhibition/${offsiteExhibtionBlock[0].node.slug}`} className="offsite-link">
									<h2 className="exhibition-title sans--large" dangerouslySetInnerHTML={{ __html: offsiteExhibtionBlock[0].node.formattedTitle }} />
									<div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: offsiteExhibtionBlock[0].node.locations[0].location.title }} />
									<div className="sans--regular mb0" dangerouslySetInnerHTML={{ __html: useHandleDate(offsiteExhibtionBlock[0].node.endDateText, offsiteExhibtionBlock[0].node.startDate, offsiteExhibtionBlock[0].node.endDate, offsiteExhibtionBlock[0].node.exhibitionStatus) }} />
								</Link>
							</div>
						</div>
          </div>
        </div>
      )}
      <div className="exhibition-row">
        <div className="container row">
          <div className="col-sm-12 col-xs-6 offset-sm-2 col">
            <Link to="/exhibitions/" className="see-all-link">
              <span className="see-all-text">See all exhibitions</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

HomeExhibitions.propTypes = {
  accentColor: PropTypes.shape().isRequired,
};
