import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ImageFadeIn from "react-image-fade-in";

export const SupportGertrude = ({ block }) => {
  const renderblock = support => {
    if (!support.link.startsWith("/")) {
      return (
        <a
          href={support.link}
          rel="noreferrer"
          className="support-tile col"
          key={support}
        >
					<ImageFadeIn
						src={support.image.url}
						alt={support.image.alt}
						className="support-featured-image"
					/>
          <div className="support-tile-text-wrapper">
            <h3
              className="support-name sans--regular"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: support.text,
              }}
            />
          </div>
        </a>
      )
    }

    if (support.link) {
      return (
        <Link
          to={support.link}
          key={support.id}
          aria-labelledby={support.id}
          className="support-tile col"
        >
          <ImageFadeIn
						src={support.image.url}
						alt={support.image.alt}
						className="support-featured-image"
					/>
          <div className="support-tile-text-wrapper">
            <h3
              id={support.id}
              className="support-name sans--regular"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: support.text,
              }}
            />
          </div>
        </Link>
      )
    }

    return (
      <div key={support.id} className="support-tile col">
        <Img
          fluid={{ ...support.image.fluid, aspectRatio: 1 }}
          alt={support.image.alt}
          className="support-featured-image"
        />
        <div className="support-tile-text-wrapper">
          <h3
            className="support-name sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: support.text,
            }}
          />
        </div>
      </div>
    )
  }

  return (
    <section aria-labelledby="home-support" className="home-support-gertrude">
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <h2
            id="home-support"
            className="sidebar-text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: block.sidebarText,
            }}
          />
        </div>
        <div className="col-sm-10 col-xs-6 col row support-gertrude-home spacing-0 custom-scroll-x">
          <div className="inner-wrapper">
            {block.support.length > 0 &&
              block.support.map(support => renderblock(support))}
          </div>
        </div>
      </div>
    </section>
  )
}

SupportGertrude.propTypes = {
  block: PropTypes.shape().isRequired,
}
