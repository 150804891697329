import { useStaticQuery, graphql } from 'gatsby';

// current/upcoming contemporary max 2 current || max 2 upcoming = carousel
// current/upcoming glasshouse max 1 current || max 1 upcoming = image
// current/upcoming offsite max 1 current || max 1 upcoming = image
// gallery closed. max 2 blocks image and text. Exactly the same as contemp and glasshouse = image

export const useSingleExhibitionQuery = () => {
  const data = useStaticQuery(graphql`
    fragment exhibitionFields on DatoCmsSingleExhibition {
      id
      formattedTitle
      slug
      exhibitionStatus
      featuredImage {
        alt
        fluid(maxHeight: 600) {
          ...GatsbyDatoCmsSizes_noBase64
        }
				url(imgixParams: { h: "700", ar: "5:3", fit: "crop", crop: "focalpoint" })
      }
      locations {
        details
        location {
          id
          title
          offsite
        }
      }
      endDateText
      endDate
      startDate
      galleryName
    }
    query {
      currentExhibitions: allDatoCmsSingleExhibition(
        sort: { fields: startDate, order: ASC }
        filter: { exhibitionStatus: { ne: "PAST" } }
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      currentContemporaryExhibitions: allDatoCmsSingleExhibition(
        limit: 2,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "CURRENT"}, locations: {elemMatch: {location: {title: {eq: "Gertrude Contemporary"}}}}, meta: { status: { ne: "draft" } } }
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      upcomingContemporaryExhibitions: allDatoCmsSingleExhibition(
        limit: 2,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "UPCOMING"}, locations: {elemMatch: {location: {title: {eq: "Gertrude Contemporary"}}}}}
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      currentGlasshouseExhibitions: allDatoCmsSingleExhibition(
        limit: 1,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "CURRENT"}, locations: {elemMatch: {location: {title: {eq: "Gertrude Glasshouse"}}}}, meta: { status: { ne: "draft" } } }
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      upcomingGlasshouseExhibitions: allDatoCmsSingleExhibition(
        limit: 1,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "UPCOMING"}, locations: {elemMatch: {location: {title: {eq: "Gertrude Glasshouse"}}}}}
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      currentOffsiteExhibitions: allDatoCmsSingleExhibition(
        limit: 1,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "CURRENT"}, locations: {elemMatch: {location: {offsite: {eq: true}}}}}
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
      upcomingOffsiteExhibitions: allDatoCmsSingleExhibition(
        limit: 1,
        sort: {fields: startDate, order: ASC},
        filter: {exhibitionStatus: {eq: "UPCOMING"}, locations: {elemMatch: {location: {offsite: {eq: true}}}}}
      ) {
        edges {
          node {
            ...exhibitionFields
          }
        }
      }
    }
  `);

  return data;
};
