import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { formatDate } from '../formatDate';
import { DividerBlock } from '../dividerBlock';
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

export const PublicPrograms = ({ block, accentColor }) => {
  const calculateClass = (idx) => {
    if (idx === 0 && block.program.length === 1) {
      return 'col-sm-6 col-xs-5';
    }

    if (idx === 0) {
      return 'col-sm-4 col-xs-5';
    }

    return 'col-xs-5 offset-xs-1 col-sm-6';
  };

  if (block.program.length === 0) {
    return null;
  }

  return (
    <section aria-labelledby="home-public-programs" className="home-public-programs">
      <div className="public-programs-content container row">
        <div className="col-sm-2 col-xs-6 col">
          <h2 id="home-public-programs" className="sidebar-text">{block.sidebarText}</h2>
        </div>
        {block.program.map((program, idx) => (
          <Link
            className={`public-program-tile col ${calculateClass(idx)}`}
            key={program.id}
            aria-labelledby={program.id}
            to={`/public-program/${program.slug}`}
          >
            <Img fluid={{ ...program.featuredImage.fluid, aspectRatio: 16 / 9 }} alt={program.featuredImage.alt || `Public program image ${idx + 1}`} />
            <div className="image-text-row-text-wrapper">
              <h3
                id={program.id}
                className="title sans--medium"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: program.formattedTitle,
                }}
              />
              <p className="program-event-info">
                <span>{program.publicProgramType.title}</span>
                <span>
								{dayjs(program.event[0]?.performanceDate).format("dddd D MMMM YYYY, h:mma")}
                </span>
                <span>{program.location.title}</span>
              </p>
            </div>
          </Link>
        ))}
      </div>
      <div className="public-programs-footer container row reverse">
        <div className="divider col-sm-5 col-xs-3 offset-xs-3 col">
          <DividerBlock accentColor={accentColor} />
        </div>
        <div className="link col-sm-5 offset-sm-2 col-xs-6 col">
          <Link to="/public-program" className="see-all-link">
            <span className="see-all-text">See all public programs</span>
          </Link>
        </div>
      </div>
    </section>
  );
};

PublicPrograms.propTypes = {
  block: PropTypes.shape().isRequired,
  accentColor: PropTypes.shape().isRequired,
};
