import { useFormatDate } from './useFormatDate';

const formatDate = (date) => `${useFormatDate(date).monthLong} ${useFormatDate(date).date}, ${useFormatDate(date).year}`;

export const useHandleDate = (endDateText, startDate, endDate, status) => {
  if (endDateText) {
    return endDateText;
  }

  return status === 'CURRENT' ? `Open until ${formatDate(startDate)}` : `Opening ${formatDate(endDate)}`;
};
