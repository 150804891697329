import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Layout } from '../components/layout';
import { LatestNews } from '../components/home/latestNews';
import { PublicPrograms } from '../components/home/publicPrograms';
import { ArtistRow } from '../components/home/artistRow';
import { Programs } from '../components/home/programs';
import { SupportGertrude } from '../components/home/supportGertrude';
import { DividerBlock } from '../components/dividerBlock';
import { HomeExhibitions } from '../components/home/homeExhibitions';
import ImageFadeIn from "react-image-fade-in"

const displayContentBlock = (block, accentColor) => {
  const { name } = block.model;
  if (name === 'Latest News') {
    return <LatestNews key={block.id} block={block} />;
  }

  if (name === 'Public programs') {
    return <PublicPrograms key={block.id} block={block} accentColor={accentColor} />;
  }

  if (name === 'Artist Row') {
    return <ArtistRow key={block.id} block={block} />;
  }

  if (name === 'Programs') {
    return <Programs key={block.id} block={block} />;
  }

  if (name === 'Support Gertrude') {
    return <SupportGertrude key={block.id} block={block} accentColor={accentColor} />;
  }

  return null;
};

const IndexPage = ({ data }) => (
  <Layout
    bgcolor={data.home.backgroundColor}
    wrapperClass="home"
    accentColor={data.home.accentColor}
    showAcknowledgement={true}
    showLocations={true}
  >
    <HelmetDatoCms seo={data.home.seoMetaTags} />
    <h1 className="vh">Gertrude Contemporary</h1>
    <HomeExhibitions accentColor={data.home.accentColor} />
    {data.home.content.map((block) => (
      displayContentBlock(block, data.home.accentColor)
    ))}
    <div className="home-bottom-divider container row">
      <div className="col-sm-6 col-xs-4 offset-sm-2 offset-xs-1 col">
        <DividerBlock accentColor={data.home.accentColor} />
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query HomeQuery {
    home: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      content {
        ... on DatoCmsLatestNews {
          id
          model {
            name
          }
          article {
            formattedTitle
            featuredImage {
              alt
              fluid(maxWidth: 500, imgixParams: { fm: "jpg", auto: "compress", ar: "5:3", fit: "crop", crop: "focalpoint" }) {
                ...GatsbyDatoCmsSizes_noBase64
              }
            }
            slug
          }
          sidebarText
        }
        ... on DatoCmsPublicProgramsThumb {
          id
          model {
            name
          }
          id
          sidebarText
          program {
            id
            slug
            featuredImage {
              alt
              fluid(maxHeight: 500, imgixParams: { fm: "jpg", auto: "compress", ar: "5:3", fit: "crop", crop: "focalpoint" }) {
                ...GatsbyDatoCmsSizes_noBase64
              }
            }
            formattedTitle
            publicProgramType {
              title
            }
            event {
              performanceDate
            }
            location {
              title
            }
          }
        }
        ... on DatoCmsArtistRow {
          id
          model {
            name
          }
          sidebarText
        }
        ... on DatoCmsOurProgram {
          id
          model {
            name
          }
          sidebarText
          program {
            id
            image {
              alt
              fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", ar: "5:3", fit: "crop", crop: "focalpoint" }) {
                ...GatsbyDatoCmsSizes_noBase64
              }
							url(imgixParams: { h: "500", ar: "5:3", fit: "crop", crop: "focalpoint" })
            }
            link
            text
          }
        }
        ... on DatoCmsSupportGertrude {
          id
          model {
            name
          }
          sidebarText
          support {
            id
            image {
              alt
              fluid(maxHeight: 450, imgixParams: { fm: "jpg", auto: "compress", ar: "5:3", fit: "crop", crop: "focalpoint" }) {
                ...GatsbyDatoCmsSizes_noBase64
              }
							url(imgixParams: { h: "500", ar: "5:3", fit: "crop", crop: "focalpoint" })
            }
            link
            text
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};
