import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

export const ArtistRow = ({ block: { sidebarText } }) => {
  const data = useStaticQuery(graphql`
    query {
      currentArtists: allDatoCmsArtist(
        sort: {
          fields: [meta___publishedAt],
          order: DESC
        },
        limit: 20,
        filter: { studioArtistStatus: { eq: "CURRENT" }, meta: { isValid: { eq: true }, status: { ne: "draft" } } }
      ) {
        edges {
          node {
            profileImage {
              fluid(maxHeight: 300, imgixParams: { fm: "jpg", auto: "compress", ar: "1:1", fit: "crop", crop: "focalpoint" }) {
                ...GatsbyDatoCmsSizes_noBase64
              }
              alt
            }
            id
            name
            slug
            endDate
          }
        }
      }
    }
  `);
  const current = data.currentArtists.edges;

  const [artists, setArtists] = useState([]);

  useEffect(() => {
    const currentSample = current
      .map((x) => ({ x, r: Math.random() }))
      .sort((a, b) => a.r - b.r)
      .map((a) => a.x)
      .slice(0, 5);

    setArtists(currentSample);
  }, [current]);

  if (artists.length > 0) {
    return (
      <section aria-labelledby="home-artists" className="home-artists">
        <div className="container row">
          <div className="col-sm-2 col-xs-6 col">
            <h2 id="home-artists" className="sidebar-text">{sidebarText}</h2>
          </div>
          <div className="tiles tiles-5 col-sm-10 col-xs-6 col row reverse spacing-0">
            {artists.map(({ node: artist }) => (
              <div className="artist-tile tile col" key={artist.id}>
                <a href={`/artist/${artist.slug}`}>
                  {artist.profileImage && (
                    <Img fluid={{ ...artist.profileImage.fluid, aspectRatio: 1 }} alt={artist.name} className="tile-image" />
                  )}
                  <div className="tile-text-wrap">
                    <p className="name">{artist.name}</p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="container row reverse">
          <div className="col-sm-12 offset-sm-2 col-xs-6 col">
            <Link to="/studios/" className="see-all-link">
              <span className="see-all-text">See all studio artists</span>
            </Link>
          </div>
        </div>
      </section>
    );
  }

  return null;
};

ArtistRow.propTypes = {
  block: PropTypes.shape().isRequired,
};
