import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ImageFadeIn from "react-image-fade-in";

export const Programs = ({ block }) => {
  const renderblock = (program) => {
    if (program.link) {
      return (
        <Link
          to={program.link}
          key={program.id}
          aria-labelledby={program.id}
          className="program-tile col"
        >
					<ImageFadeIn
						src={program.image.url}
						alt={program.image.alt}
						className="program-featured-image"
					/>
          <h3
            id={program.id}
            className="program-name sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: program.text,
            }}
          />
        </Link>
      );
    }

    return (
      <div key={program.id} className="program-tile col">
        <Img
          fluid={{ ...program.image.fluid }}
          alt={program.image.alt || 'Programs image'}
          className="program-featured-image"
        />
        <div className="program-tile-text-wrapper">
          <h3
            className="program-name sans--regular"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: program.text,
            }}
          />
        </div>
      </div>
    );
  };

  if (block) {
    return (
      <>
        <section aria-labelledby="home-programs" className="home-programs container row">
          <div className="home-sidebar col-sm-2 col-xs-6 col">
            <h2
              id="home-programs"
              className="sidebar-text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: block.sidebarText,
              }}
            />
          </div>
          <div className="home-programs-content col-sm-10 col-xs-6 col row spacing-0 custom-scroll-x">
            <div className="inner-wrapper">
              {block.program.length > 0
                && block.program.map((program) => renderblock(program))}
            </div>
          </div>
        </section>
      </>
    );
  }

  return null;
};

Programs.propTypes = {
  block: PropTypes.shape().isRequired,
};
