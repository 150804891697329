import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

export const LatestNews = ({ block }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSingleNews {
        edges {
          node {
            formattedTitle
            featuredImage {
              alt
              fluid(maxHeight: 300, imgixParams: { fm: "jpg", auto: "compress", ar: "5:3", fit: "crop", crop: "focalpoint"}) {
                ...GatsbyDatoCmsSizes_noBase64
              }
            }
            slug
          }
        }
      }
    }
  `);

  let thisBlock = block.article;
  if (block.article === null) {
    thisBlock = data.allDatoCmsSingleNews.edges[0].node;
  }

  return (
    <section aria-labelledby="home-news" className="home-latest-news">
      <div className="container row">
        <div className="col-sm-2 col-xs-6 col">
          <h2 id="home-news" className="sidebar-text">{block.sidebarText}</h2>
        </div>
        <div className="col-sm-12 col-xs-6 col row reverse news-article-home">
          <div className="image-text-row-image-wrapper col-xs-4 col">
            <Link
              aria-labelledby={thisBlock.id}
              to={`/news/${thisBlock.slug}`}
            >
              <Img fluid={{ ...thisBlock.featuredImage.fluid }} alt={thisBlock.featuredImage.alt || 'Latest news image'} />
						</Link>
          </div>
          <div className="image-text-row-text-wrapper">
            <h2
              id={thisBlock.id}
              className="gothic--medium"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: thisBlock.formattedTitle,
              }}
            />
            <Link to={`/news/${thisBlock.slug}`} className="read-more">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

LatestNews.propTypes = {
  block: PropTypes.shape().isRequired,
};
